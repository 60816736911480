
// tslint:disable
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';


/**
 * WhereClause entity interface.
 */
export interface IWhereClause {
  Column?: string;
  Operator?: string;
  Value?: string;
  IsString?: boolean;
  Conjunction?: string;
  ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * TableQueryResponseEntity entity interface.
 */
export interface ITableQueryResponseEntity {
  JsonResults?: string;
  Count?: number;
  IsError?: boolean;
  ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * WhereClause entity class.
 */
export class WhereClauseExtensionClass implements IWhereClause {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Column: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Operator: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Value: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsString: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Conjunction: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Column = odataObject.Column;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Operator = odataObject.Operator;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Value = odataObject.Value;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsString = odataObject.IsString;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Conjunction = odataObject.Conjunction;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]['@odata.type']) {
            var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
            }
          } else {
            this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }

  }
}

/**
 * TableQueryResponseEntity entity class.
 */
export class TableQueryResponseEntityExtensionClass implements ITableQueryResponseEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public JsonResults: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Count: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsError: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.JsonResults = odataObject.JsonResults;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Count = (odataObject.Count) ? parseInt(odataObject.Count, 10) : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsError = odataObject.IsError;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]['@odata.type']) {
            var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
            }
          } else {
            this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }

  }
}
