import React from 'react';
import { ISearchState } from '../table-browser';

interface IQuerySearchComponentProps {
    searchState: ISearchState;
    updateSearchState: (state: ISearchState) => void;
    search: () => void;
}

export const QuerySearchComponent: React.FC<IQuerySearchComponentProps> = ({ searchState, updateSearchState, search }) => {
    const [colInputVal, setColInputVal] = React.useState<string>('');
    const updateSchema = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState({ ...searchState, schema: e.target.value });
    };

    const updateTable = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState({ ...searchState, table: e.target.value });
    };

    const updateOrderBy = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState({ ...searchState, orderBy: e.target.value });
    };

    const updateIsAscending = () => updateSearchState({ ...searchState, isAscending: !searchState.isAscending });

    const updateTop = (e: React.ChangeEvent<HTMLSelectElement>) =>
        updateSearchState({ ...searchState, top: parseInt(e.target.value, 10), skip: 0 });

    const updateColInputVal = (e: React.ChangeEvent<HTMLInputElement>) => setColInputVal(e.target.value);

    const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => updateSearchState({ ...searchState, password: e.target.value });
    const handleDeleteColumn = (col: string) => {
        const columns = searchState.columns;
        const idx = columns.findIndex(c => c === col);
        columns.splice(idx, 1);
        updateSearchState({ ...searchState, columns });
    };

    const addCol = () => {
        if (!searchState.columns.includes(colInputVal) && colInputVal !== '') {
            const cols = [...searchState.columns, colInputVal];
            updateSearchState({ ...searchState, columns: cols });
            setColInputVal('');
        }
    };

    const shouldSubmitColInput = (e: React.KeyboardEvent) => (e.key.toLowerCase() === 'enter' ? addCol() : null);
    return (
        <div className={'controls'}>
            <div className='form-group'>
                <div className='control'>
                    <label htmlFor='password'>Password</label>
                    <input
                        type='password'
                        id='password'
                        name={'Password'}
                        value={searchState.password}
                        onChange={updatePassword}
                        className={'controls__input'}
                    />
                </div>
            </div>
            <div className='controls__form-group'>
                <div className='controls__label-wrapper'>
                    <label htmlFor='schema'>Table Schema</label>
                    <input
                        type='text'
                        name={'schema'}
                        value={searchState.schema}
                        onChange={updateSchema}
                        className={'controls__input'}
                        id={'schema'}
                    />
                </div>
                <div className='control'>
                    <label htmlFor='table'>Table Name</label>
                    <input type='text' id={'table'} name={'table'} value={searchState.table} onChange={updateTable} />
                </div>
            </div>
            <div className='form-group'>
                <div className='control'>
                    <label htmlFor='column'>Add Column</label>
                    <input
                        type='text'
                        id='column'
                        name={'Columns'}
                        value={colInputVal}
                        onKeyUp={shouldSubmitColInput}
                        onChange={updateColInputVal}
                        className={'controls__input'}
                    />
                    <button type={'button'} onClick={addCol}>
                        Add Column
                    </button>
                </div>
                <div className='controls__columns'>
                    {searchState.columns.map(col => (
                        <div className={'controls__col-chip'} key={col}>
                            {col} <span className={'controls__col-chip--delete'} onClick={() => handleDeleteColumn(col)} />
                        </div>
                    ))}
                </div>
            </div>
            <div className='form-group'>
                <div className='control'>
                    <label htmlFor='orderBy'>Order By</label>
                    <input
                        type='text'
                        id='orderBy'
                        name={'Order By'}
                        value={searchState.orderBy}
                        onChange={updateOrderBy}
                        className={'controls__input'}
                    />
                </div>
                <div className='control'>
                    <label htmlFor='isAscending'>Sort Ascending</label>
                    <input
                        type='checkbox'
                        id={'isAscending'}
                        name={'is Ascending'}
                        checked={searchState.isAscending}
                        onChange={updateIsAscending}
                    />
                </div>
            </div>
            <div className='form-group'>
                <div className='control'>
                    <label htmlFor='numResults'>Results per page</label>
                    <select name='Number of Results' value={searchState.top} onChange={updateTop}>
                        <option value='25'>25</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                    </select>
                </div>
            </div>
            <button onClick={search} type={'button'}>
                Search
            </button>
        </div>
    );
};
